import React from 'react';
import './../index.css';
import facebook_icon from './../images/facebook_icon.png';
import instagram_icon from './../images/instagram_icon.png';
export class Title extends React.Component {

  render() {

    return (
      <>
        {
          /*
          * Title section
          */
        }
        <div className='banner'>
          <div className='titleBackground'>
            <a href='https://www.facebook.com/100086442454609/' rel='noreferrer' target='_blank'>
              <img className='socialMediaImage' src={facebook_icon} alt="facebook" />
            </a>
            <div className='titleImage'></div>
            <a href='https://www.instagram.com/elevatedbakingpagosa/' rel='noreferrer' target='_blank'>
              <img className='socialMediaImage' src={instagram_icon} alt="instagram" />
            </a>
          </div>
        </div>

      </>
    );
  }


}

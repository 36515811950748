import React from 'react';
import './../index.css';
import {
  Routes,
  Link,
  Route,
} from "react-router-dom";
import { About } from './About';
import { Bread } from './Bread';
import { EBSite } from './EBSite';
import './../fonts/Bakerie/BakerieSmooth-Black.otf';
import { GlutenFree } from './GlutenFree';
import { Contact } from './Contact';
import { Rolls } from './Rolls';
import { Cookies } from './Cookies';
import facebook_icon from './../images/facebook_icon.png';
import instagram_icon from './../images/instagram_icon.png';
import { Order } from './Order';
import { Title } from './Title';
import { Locations } from './Locations';
import { slide as Menu } from 'react-burger-menu';
import { BrowserRouter } from "react-router-dom";


export class NavBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };

    this.closeMenu = this.closeMenu.bind(this);
    this.toggleMenuState = this.toggleMenuState.bind(this);

  }


  closeMenu() {
    this.setState({ menuOpen: false });
  }

  toggleMenuState(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  render() {
    return (
      <>
        {
          /*
          * Bread section
          */
        }
        <BrowserRouter>
          <Menu width={"calc(200px + 0.390625vw)"} onStateChange={(state) => this.toggleMenuState(state)} isOpen={this.state.menuOpen} left>
            <Link className='navLink' to="/" onClick={this.closeMenu}>Home</Link>
            <Link className='navLink' to="/bread" onClick={this.closeMenu}>Bread</Link>
            <Link className='navLink' to="/rolls" onClick={this.closeMenu}>Rolls</Link>
            <Link className='navLink' to="/cookies" onClick={this.closeMenu}>Cookies</Link>
            <Link className='navLink' to="/glutenfree" onClick={this.closeMenu}>Gluten Free</Link>
            <Link className='navLink' to="/locations" onClick={this.closeMenu}>Locations</Link>
            <Link className='navLink' to="/order" onClick={this.closeMenu}>Order</Link>
            <Link className='navLink' to="/contact" onClick={this.closeMenu}>Contact</Link>
          </Menu>
          <Title />
{/*           <div className='header'>
            <table className='bannerTable'>
              <tr className='bannerRow'>
              <td className='bannerCell'>
                <Title />
              </td> 
                <td className='bannerCell'>
                  <nav className='nav'>
                    <table className='navTable'>
                      <tbody>
                        <tr className='navTableRow'>
                          <td>
                            <Link className='navLink' to="/">Home</Link>
                          </td>
                          <td>
                            <Link className='navLink' to="/bread">Bread</Link>
                          </td>
                          <td>
                            <Link className='navLink' to="/rolls">Rolls</Link>
                          </td>
                          <td>
                            <Link className='navLink' to="/cookies">Cookies</Link>
                          </td>
                          <td>
                            <Link className='navLink' to="/glutenfree">Gluten Free</Link>
                          </td>
                          <td>
                            <Link className='navLink' to="/Locations">Locations</Link>
                          </td>
                          <td>
                            <Link className='navLink' to="/Order">Order</Link>
                          </td>
                          <td>
                            <Link className='navLink' to="/contact">Contact</Link>
                          </td>
                          <td>
                            <Link className='navLink' to="/about">About</Link>
                          </td>
                          <td>
                            <table className='navTable'>
                              <tbody>
                                <tr className='navTableRow'>
                                  <td>
                                    <a href='https://www.facebook.com/100086442454609/' rel='noreferrer' target='_blank'>
                                      <img className='socialMediaImage' src={facebook_icon} alt="facebook" />
                                    </a>
                                  </td>
                                  <td>
                                    <a href='https://www.instagram.com/elevatedbakingpagosa/' rel='noreferrer' target='_blank'>
                                      <img className='socialMediaImage' src={instagram_icon} alt="instagram" />
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </nav>
                </td>
              </tr>
            </table>
          </div> */}

          <Routes>
            <Route path="/" element={<EBSite />} />
            <Route path="/about" element={<About />} />
            <Route path="/bread" element={<Bread
              docMap={this.props.docMap}
              recipeMap={this.props.recipeMap}
              ingredientMap={this.props.ingredientMap}
            />} />
            <Route path="/rolls" element={<Rolls
              docMap={this.props.docMap}
              recipeMap={this.props.recipeMap}
              ingredientMap={this.props.ingredientMap}
            />} />
            <Route path="/cookies" element={<Cookies
              docMap={this.props.docMap}
              recipeMap={this.props.recipeMap}
              ingredientMap={this.props.ingredientMap}
            />} />
            <Route path="/glutenfree" element={<GlutenFree
              docMap={this.props.docMap}
              recipeMap={this.props.recipeMap}
              ingredientMap={this.props.ingredientMap}
            />} />
            <Route path="/locations" element={<Locations />} />
            <Route path="/order" element={<Order
              docMap={this.props.docMap}
              recipeMap={this.props.recipeMap}
              ingredientMap={this.props.ingredientMap}
            />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </BrowserRouter>

      </>
    );
  }


}

import React from 'react';
import './../index.css';
import { Disclaimer } from './Disclaimer';
import { renderProductTableRow } from './../util/TableRows';
import { Container } from "react-bootstrap";

export class Bread extends React.Component {

  constructor(props) {
    super(props);
  }


  render() {

    return (
      <>
        {
          /*
          * Bread section
          */
        }
        <Container>
        <table className='productTable'>
            <tbody>
              {
                [...this.props.docMap].map(([key, value]) => {
                  if (value.category === 'Bread' && value.on_website) {
                    return renderProductTableRow(value, this.props.recipeMap, this.props.ingredientMap);
                  } else {
                    return "";
                  }
                }
                )
              }
            </tbody>
          </table>
          <Disclaimer />
          </Container>
          </>
    );
  }


}

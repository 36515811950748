import * as React from 'react';
import './../index.css';
import { OrderRecord } from '../util/ProductMap';
import { roundToDollars } from '../util/MoneyUtils';
import {
  Button,
  Container,
  Form,
  Row,
  Col,
  Modal,
  Table,
} from "react-bootstrap";

export class Order extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      orderMap: new Map(),
      fullName: '',
      fromEmail: '',
      completionDate: '',
      amPm: '',
      phone: '',
      specialRequest: "",
      email: 'lisa@elevatedbaking.biz',
      isOpen: false,
      dummyVal: "",
      modalString: "",
      validated: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleModal = this.toggleModal.bind(this);

    this.fullNameRef = React.createRef();
    this.emailRef = React.createRef();
    this.completionDateRef = React.createRef();
    this.amPmRef = React.createRef();
    this.phoneRef = React.createRef();
  }

  handleClose = () => this.setState({ isOpen: false });

  handleChange(event) {
    let productName = event.target.name;
    productName = productName.replace("_qty", "");
    let order = this.state.orderMap.get(productName);
    if (event.target.name.endsWith("_qty")) {
      order.quantity = event.target.value;
      this.state.orderMap.set(productName, order);
    } else if (event.target.id === 'full_name') {
      this.setState({
        fullName: event.target.value
      });
    } else if (event.target.id === 'from_email') {
      this.setState({
        fromEmail: event.target.value
      });
    } else if (event.target.id === 'completion_date') {
      this.setState({
        completionDate: event.target.value
      });
    } else if (event.target.id === 'phone') {
      this.setState({
        phone: event.target.value
      });
    } else if (event.target.id === 'special_request') {
      this.setState({
        specialRequest: event.target.value
      });
    } else if (event.target.id === 'am') {
      this.setState({
        amPm: event.target.value
      });
    } else if (event.target.id === 'pm') {
      this.setState({
        amPm: event.target.value
      });
    } else {
      order.isSelected = event.target.checked;
      this.state.orderMap.set(productName, order);
    }
    /**
     * Force the render to refresh.
     */
    this.setState({
      dummyVal: "x",
    })

  }

  toggleModal() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  validFields() {
    if ('' === this.state.fullName) {
      this.setState({
        isOpen: true,
        modalString: "Full Name required!",
      });
      this.fullNameRef.current.focus();
      return false;
    } else if ('' === this.state.fromEmail) {
      this.setState({
        isOpen: true,
        modalString: "Your email address is required!",
      });
      this.emailRef.current.focus();
      return false;
    } else if (this.state.fromEmail.indexOf('@') === -1) {
      this.setState({
        isOpen: true,
        modalString: "Invalid email address!",
      });
      this.emailRef.current.focus();
      return false;
    } else if ('' === this.state.phone) {
      this.setState({
        isOpen: true,
        modalString: "Please enter your phone number!",
      });
      this.phoneRef.current.focus();
      return false;
    } else if ('' === this.state.completionDate) {
      this.setState({
        isOpen: true,
        modalString: "Please enter an desired completion date!",
      });
      this.completionDateRef.current.focus();
      return false;
    } else if ('' === this.state.amPm) {
      this.setState({
        isOpen: true,
        modalString: "Please enter AM or PM, morning or afternoon, on the desired completion date!",
      });
      this.amPmRef.current.focus();
      return false;
    } else if (!this.anySelected()) {
      this.setState({
        isOpen: true,
        modalString: "Please select an item!",
      });
      return false;
    } else if (0 === this.getTotalOrders()) {
      this.setState({
        isOpen: true,
        modalString: "Please select a quantity for the selected item(s)!",
      });
      return false;
    } else {
      return true;
    }
  }

  getTotalOrders() {
    let total = 0;
    this.state.orderMap.forEach(function (value, key) {
      total = total + parseInt(value.quantity);
    });
    return total;
  }

  anySelected() {
    let anySelected = false;
    this.state.orderMap.forEach(function (value, key) {
      if (value.isSelected) {
        anySelected = true;
        return true;
      }
    });
    return anySelected;
  }

  handleSubmit(event) {
    event.preventDefault();
    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //   this.setState({ validated: true });
    //   return;
    // }
    const templateId = 'template_92p69nb';
    let orderString = '<p>Orders:</p>';
    let grandTotalPrice = 0;
    this.state.orderMap.forEach(function (value, key) {
      if (value.isSelected) {
        let totalPrice = value.product.price * value.quantity;
        grandTotalPrice = grandTotalPrice + totalPrice;
        orderString = orderString + "<p>Product: " + value.product.name + " quantity: " + value.quantity + " total price: " + roundToDollars(totalPrice) + "</p>";
      }
    });


    if (this.validFields()) {

      this.sendOrder(templateId, {
        orders: orderString,
        special_request: this.state.specialRequest,
        from_name: this.state.fullName,
        from_email: this.state.fromEmail,
        completion_date: this.state.completionDate,
        am_or_pm: this.state.amPm,
        phone: this.state.phone,
        reply_to: this.state.email,
        grand_total: roundToDollars(grandTotalPrice),
      })

      this.setState({
        modalString: "Your Order has been sent! Your total price for the order is: " +
          roundToDollars(grandTotalPrice) + "\n If you do not get a confirmation email within 24 hours, please email lisa@elevatedbaking.biz.",
        isOpen: true,
        fullName: "",
        fromEmail: "",
        phone: "",
        completionDate: "",
        amPm: "",
        specialRequest: "",
        orderMap: new Map(),
      })

    }
  }

  renderTableRow = (productRef, productDoc) => {
    let order = this.state.orderMap.get(productRef);
    let product = this.props.docMap.get(productRef);
    if (undefined === order) {
      order = new OrderRecord(product, "0", false);
      this.state.orderMap.set(productRef, order);
      // this.setState({
      //   dummyVal: "x",
      // })
    }
    let qtyName = productRef + "_qty";
    /**
     * This following google link works as of April 5, 2024
     */
    let image = 'https://lh3.googleusercontent.com/d/' + product.google_image_file_id
/*     let image = 'https://drive.google.com/uc?id=' + product.google_image_file_id;
 */    return (
      <>
        <tr className='productTableRow' key={productRef}>
          <td className='orderTableCellLeft'>
            <input type="checkbox"
              className='orderCheckBox'
              id={productRef}
              name={productRef}
              checked={order.isSelected}
              onChange={this.handleChange} />
            <label htmlFor={productRef}>{productDoc.name}</label>
          </td>
          <td className='productTableCellRight'>
            <img className='productImage'
              src={image}
              alt="product" />
          </td>
          <td className='productTableCellQty'>
            <label htmlFor="quantity" className='productTableCellQty'>Quantity:</label>
            <select value={order.quantity}
              onChange={this.handleChange}
              name={qtyName} id={qtyName}
              className='quantityText'>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </select>
          </td>
          <td className='productTableCellQty'>
            Price: {roundToDollars(productDoc.price)}
          </td>
          <td className='productTableCellQty'>
            Per: {productDoc.recipe_qty}
          </td>
        </tr>
      </>
    )
  }

  render() {
    const productMap = this.props.docMap;
    return (
      <>
        <Container>
          <Form className='productTable' noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
            <Row>
              <div className='formHeaderText'>
                <ul className='noDotsList'>
                  <li>Thanks for ordering!  Your satisfaction is our TOP concern.  All orders are fully refundable if you are not 100% satisfied.</li>
                  <li>Feel free to call in your order if that's easier for you.  The phone number is at the bottom of all of the pages including this one.</li>
                  <li>Please fill out the order form below by checking the box next to the product, entering the quantity, then pressing the "Submit Order" button at the top and bottom.</li>
                  {/* <li>Orders are fulfilled on <b>Tuesdays</b> and <b>Thursdays</b>.</li> */}
                  <li>
                    Each order will be confirmed with an email.  If you do NOT receive an email,
                    your order is NOT confirmed. If you do not receive a confirmation email within 24 hours of placing
                    your order, please email or call.
                  </li>
                  <li>
                    We do NOT store or sell email addresses nor phone numbers.  We simply need a phone number
                    to contact you for order details.
                  </li>

                </ul>
              </div>
            </Row>
            <Row>
              <Modal show={this.state.isOpen} onHide={this.handleClose}>
                <Modal.Body> {this.state.modalString}</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal
                className='modalDialog'
                isOpen={this.state.isOpen}
                onRequestClose={this.toggleModal}
                contentLabel="My dialog"
              >
                <div className='bodyText'>
                  {this.state.modalString}
                </div>
                <input type="button" value="Close" className="btn btn--submit" onClick={this.toggleModal} />
              </Modal>
{/*               <Button variant="dark" type="submit" size="sm">
                Submit Order
              </Button> */}
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label size='sm' htmlFor="full_name">Full Name:</Form.Label>
                  <Form.Control
                    size='sm'
                    placeholder='First Name and Last Name'
                    className="form-control"
                    ref={this.fullNameRef}
                    id='full_name'
                    type="text"
                    value={this.state.fullName}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Row>
              <Form.Group>
                <Form.Label size='sm' htmlFor="from_email">Email Address:</Form.Label>
                <Form.Control
                  size='sm'
                  placeholder='Email Address'
                  ref={this.emailRef}
                  id='from_email'
                  type="email"
                  value={this.state.fromEmail}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label size='sm' htmlFor="phone">Phone:</Form.Label>
                <Form.Control
                  size='sm'
                  ref={this.phoneRef}
                  placeholder='Phone number'
                  id='phone'
                  type="phone"
                  value={this.state.phone}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label size='sm' htmlFor="completion_date">Desired Completion Date:</Form.Label>
                <Form.Control
                  size='sm'
                  ref={this.completionDateRef}
                  id='completion_date'
                  type="date"
                  value={this.state.completionDate}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Check
                  inline
                  label="am (morning)"
                  name="group1"
                  type='radio'
                  id='am'
                  ref={this.amPmRef}
                  value='AM'
                  checked={this.state.amPm === "AM"}
                  onChange={this.handleChange}
                />
                <Form.Check
                  inline
                  label="pm (afternoon)"
                  name="group1"
                  type='radio'
                  id='pm'
                  value='PM'
                  checked={this.state.amPm === "PM"}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="special_request">Special Request:</Form.Label>
                <Form.Control
                  as="textarea"
                  size='sm'
                  rows='5'
                  cols='60'
                  id='special_request'
                  value={this.state.specialRequest}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <h3>Products (Scroll down for more...):  </h3>
              <div className="bodyText">
                <div className='scrollingTable'>
                  <Table hover>
                    <tbody>
                      {
                        [...productMap].map(([productRef, productDoc]) => {
                          if (productDoc.on_website) {
                            return this.renderTableRow(productRef, productDoc);
                          }
                        }
                        )
                      }

                    </tbody>
                  </Table>
                </div>
              </div>
              <div className='buttonArea'>
                <Button variant="dark" type="submit">
                  Submit Order
                </Button>
              </div>
            </Row>
          </Form>
        </Container>
      </>
    );
  }

  sendOrder(templateId, variables) {
    window.emailjs.send(
      'service_nhdfeu8', templateId,
      variables
    ).then(res => {
      console.log('Email successfully sent!')
    })
      // Handle errors here however you like, or use a React error boundary
      .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
    //console.log("variable= " + variables);
  }
}
